<template>
  <div name="activityData" class="page">


  <div id="printable" >
 <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mb-8" >
      <h4 class="text-lg font-semibold mb-4">Raw Data broken down by activity</h4>

  <table v-if="componentLoaded" class="table-wrapper w-full">
                              <thead class="bg-gray-100 rounded">
                                <tr>
                                         <th class="w-6/12 text-left p-2">Activity</th>
                                    <th class="w-2/8 text-center font-bold">Total</th>
                                    <th class="w-1/8 text-center">Female</th>
                                   <th class="w-1/8 text-center">Male</th>                                 
                                <th class="w-1/8 text-center">SEN</th>
                                <th class="w-1/8 text-center">PP</th>
                                 <th class="w-1/8 text-center">EAL</th>

                                </tr>
                              </thead>
                              <tbody>
                                <tr class="course" v-for="item in orderedActivities"> 
                                   <td>
                                    <div class="upper">
                                       {{ item.title }}
                                    </div>
                                  </td>
                                  <td class="text-center font-bold"> {{  processTotalForActivity(item) }}</td>
                                  <td class="text-center" v-for="value in processTypesActivity(item)">{{ value }}</td>
                                </tr>

                               
                              </tbody>
                              <tfoot>

                              </tfoot>
                            </table>

                             <p v-else>We could not find any data for your given selection.</p>

               </section>

 <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mb-8" >
      <h4 class="text-lg font-semibold mb-4">Raw Data broken down by year group</h4>
                           
                           
                          <table v-if="componentLoaded" class="table-wrapper w-full">
                              <thead class="bg-gray-100 rounded">
                                <tr>
  
                                   <th class="w-6/12 text-left p-2">Year</th>
                                   <th class="w-2/8 text-center font-bold" >Total</th>
                                    <th class="w-1/8 text-center">Female</th>
                                    <th class="w-1/8 text-center">Male</th>
                                     
                                  <th class="w-1/8 text-center" >SEN</th>
                                  <th class="w-1/8 text-center" >PP</th>
                                   <th class="w-1/8 text-center" >EAL</th>

                                </tr>
                              </thead>
                              <tbody>
                                <tr class="course" v-for="year in years"> 
                                   <td>
                                     <div class="upper">
                                       {{ year.name }}
                                    </div>
                                  </td>
                                  <td class="text-center font-bold"> {{  processTotalForYear(year) }}</td>
                                     <td class="text-center" v-for="value in processTypesYear(year)">
                                    
                                      
                                      {{ value }}</td>
                                  
                                </tr>

                               
                              </tbody>
                              <tfoot>

                              </tfoot>
                            </table>

                           

                             <p v-else>We could not find any data for your given selection.</p>
                </section>


     </div>
  </div>
</template>
x
<script>
import {
  reportGridCalculation,
  reportGridYearCalculation
} from "@/assets/js/utils";

export default {
  name: "activityData",
  props: ["activities", "years", "types"],
  computed: {
    componentLoaded() {
      return this.activities.length > 0;
    },
    orderedActivities() {
      return this.$orderBy(this.activities, ["title"]);
    }
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {
      processTotalForActivity(activity) {
      var list = this.activities.filter(function(row) {
        let filtered = true;
        filtered = row.identifier === activity.identifier;

        if (filtered) {
          filtered = row.registers != null && row.registers.length > 0;
        }

        return filtered;
      });

      if (list.length === 0) {
          return 0;
      }

      let vm = this;
      let results = reportGridCalculation(null, list);

      return results;
    },
    processTypesActivity(item) {
      var list = this.activities.filter(function(row) {
        let filtered = true;
        filtered = row.identifier === item.identifier;

        if (filtered) {
          filtered = row.registers != null && row.registers.length > 0;
        }

        return filtered;
      });

      if (list.length === 0) {
        return this.types.map(function(type) {
          return 0;
        });
      }

      let results = this.types.map(function(type) {
        return reportGridCalculation(type.value, list);
      });

      return results;
    },
    processTotalForYear(year){
        var list = this.activities.filter(function(row) {
        let filtered = true;
        filtered = row.registers != null && row.registers.length > 0;

        if (filtered) {
          filtered =
            row.registers.filter(function(reg) {
              return reg.yeargroup === year.value;
            }).length > 0;
        }

        return filtered;
      });

      if (list.length === 0) {
        return 0;
      }

      let results = reportGridYearCalculation(null, list, year);

      return results;
    }, 
    processTypesYear(year) {
      var list = this.activities.filter(function(row) {
        let filtered = true;
        filtered = row.registers != null && row.registers.length > 0;

        if (filtered) {
          let regs = row.registers.filter(reg => {
            return reg.yeargroup === year.value;
          });

          filtered = regs.length > 0;
        }

        return filtered;
      });

      if (list.length === 0) {
        return this.types.map(function(type) {
          return 0;
        });
      }

      let results = this.types.map(function(type) {
        return reportGridYearCalculation(type.value, list, year);
      });

      return results;
    },

    printReport() {
      window.print();
    }
  },
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upper {
  text-transform: uppercase;
}

.smaller {
  font-size: 75% !important;
}

select {
  font-weight: bolder;
  font-size: 13pt;
  display: inline !important;
}
</style>
